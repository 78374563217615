* {margin: 0;padding: 0;box-sizing: border-box;}
/* dashboard color change var */
:root {
    --mainBackground: #fafafa;
    --maincolor: #0e39b6;
    --maincolortwo: #008fff; 
}

body {float: left;width: 100%;box-sizing: border-box;position: relative;background-color: var(--mainBackground);overflow: hidden;}
.wrapper {float: left;width: 100%;overflow: hidden;}

/* login css  */
.login_wrap {float: left;width: 100%;height: 100vh;display: flex;flex-direction: column;justify-content: center;align-items: center;position: relative;background-image: url("../img/city-png-33552.png");background-size: cover;background-position: 25% -50%;background-repeat: no-repeat;}
.login_form {float: left;width: 400px;background-color: #fff;position: relative;box-shadow: 0px 0px 25px -9px #ccc;}
.login_Header {float: left;width: 100%;padding: 18px 30px;background-color: #eee;text-align: center;}
.login_form_main {float: left;width: 100%;padding: 25px 30px 35px;}
.login_wrap h1 {font-size: 23px;font-weight: 700;margin: 0;}
.form-group {float: left;width: 100%;margin: 0 0 15px;}
.form-group label {float: left;width: 100%;font-weight: 500;font-size: 14px;margin-bottom: 5px;}
.form-group input {height: 40px;box-shadow: none !important;outline: none !important;border-radius: 0px;padding: 8px 12px;font-size: 13px;}
.form-group textarea {height: 100px;box-shadow: none !important;outline: none !important;border-radius: 0px;padding: 8px 12px;font-size: 13px;resize: none;}
.cmn_bigbtn, .cmn_bigbtn:focus, .cmn_bigbtn:active {width: 100%;padding: 9.4px 10px;font-size: 16px;line-height: normal;background: linear-gradient(45deg, var(--maincolor), var(--maincolortwo));border: 0px;border-radius: 0px;}
.cmn_bigbtn:hover {background: linear-gradient(45deg, var(--maincolor), var(--maincolor));border-color: var(--maincolor); }
.form-group-btn {float: left;width: 100%;margin: 7px 0 0;}
.form-group .form-control.error-form {border-color: red;}
.errormendatorr {float: left;width: 100%;font-size: 13px;color: red;margin: 4px 0 0;}
.errormendatorr i {margin-right: 4px;}
.formInput {float: left;width: 100%;position: relative;}
.eyeshowbtn {float: left;width: auto;position: absolute;top: 0;right: 0;height: 100%;border: 0px;background: transparent;padding: 5px 11px;display: flex;justify-content: center;align-items: center;font-size: 17px;}
/* login css  */

.dash_columgnset {float: right;display: flex;position: relative;margin: 8px 0 0;}
.dash_columgnset .columndahs {font-size: 14px;display: flex;flex-direction: column;margin-right: 10px;background-color: #fff;padding: 4px 8px;border-radius: 10px;}
.dash_columgnset .columndahs label {font-weight: 400;color: rgb(14, 57, 182);}
.dash_columgnset .columndahs:last-child {margin-right: 0px;}

/* forgot password and reset password css */
.forgot_password {float: right;width: auto;font-size: 13px;}
.forgot_password i {margin: 0 5px 0px 0px;float: none;}
/* forgot password and reset password css */

/* profile css  */
.profile_section {float: left;width: 100%;height: 100%;position: relative;}
.inputText {float: left;width: 100%;height: 40px;box-shadow: none !important;outline: none !important;border-radius: 0px;padding: 8px 12px;font-size: 13px;border: 1px solid #dee2e6;background-color: #f5f5f5;cursor: no-drop;}
/* profile css  */

/* dashboard css */
.dashboard_wrapper {float: left;width: 100%;height: 100%;position: relative;}
/* dashboard css */

/* main wrapper css */
.adminmain {float: left;width: 100%;position: relative;display: flex;justify-content: flex-start;height: 100vh;}
.sidebar {background: linear-gradient(45deg, var(--maincolor), var(--maincolortwo));flex-basis: 22%;float: left;height: 100vh;transition: .2s;width: 100%;border-radius: 0px 18px 18px 0px;}
.navbarHeader {float: left;width: 100%;height: 71px;padding: 22px 25px;border-bottom: 1px solid #008fff;}
.navbarHeader h1 {margin: 0px;float: left;width: auto;font-size: 20px;color: #fff;}
.flex-column.nav {float: left;width: 100%;}
.flex-column .nav-link {float: left;width: 100%;padding: 17px 23px;display: flex;font-size: 14px;font-weight: 500;color: #bbd3f5;line-height: normal;border-bottom: 1px solid #008fff;position: relative;-webkit-transition: 0.5s;-moz-transition: 0.5s;-ms-transition: 0.5s;-o-transition: 0.5s;transition: 0.5s;}
.flex-column .nav-link.active {color: #fff;-webkit-transition: 0.5s;-moz-transition: 0.5s;-ms-transition: 0.5s;-o-transition: 0.5s;transition: 0.5s;}
.flex-column .nav-link:hover {color: #fff;-webkit-transition: 0.5s;-moz-transition: 0.5s;-ms-transition: 0.5s;-o-transition: 0.5s;transition: 0.5s;}
.flex-column .nav-link i {margin-right: 10px;}
.sidebar.active .flex-column .nav-link {padding: 17px 18px;}
.sidebar.active .flex-column .nav-link i {font-size: 20px;float: none;margin: 0 auto;display: table;}
.flex-column .nav-link::after{content: "";float: left;width: 0px;height: 100%;position: absolute;left: 0;top: 0;background-color: #fff;-webkit-transition: 0.5s;-moz-transition: 0.5s;-ms-transition: 0.5s;-o-transition: 0.5s;transition: 0.5s;border-radius: 0px 10px 10px 0px;}
.flex-column .nav-link.active::after, .flex-column .nav-link:hover::after{width: 4px;-webkit-transition: 0.5s;-moz-transition: 0.5s;-ms-transition: 0.5s;-o-transition: 0.5s;transition: 0.5s;}
.panelwrapper {float: left;height: 100vh;flex-basis: calc(100% - 22%);position: relative;}
.sidebar.active {flex-basis: 4%;}
.flex-column .nav-link span {float: left;display: inline-block;white-space: nowrap;}
.sidebar.active .flex-column .nav-link span {opacity: 0;display: none;}
.backbtn {font-size: 14px;cursor: pointer;}
.add_backbtn i {margin-right: 4px;}
/* main wrapper css */

/* Hide scrollbar for Chrome, Safari and Opera */
.ScrollbarsWrapper .view {float: left;width: 100%;}
.ScrollbarsWrapper .view::-webkit-scrollbar, .ScrollbarsSidebar .view::-webkit-scrollbar {display: none;}
.ScrollbarsWrapper .view, .ScrollbarsSidebar .view {-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */margin-bottom: auto !important;}
.ScrollbarsSidebar .view{margin-bottom: auto !important;width: 100%;}
/* page common content css end here */

/* contend header */
.header_common {float: left;width: 100%;display: flex;background-color: #fff;padding: 10px 10px;position: relative;align-items: center;height: 60px;}
.header_common::after {content: "";float: left;width: 100%;height: 100%;position: absolute;transform: translateX(-99%);left: 0;background-color: #fff;z-index: -9;}
.toggle-btn {border: 0px;background: linear-gradient(45deg, var(--maincolor), var(--maincolortwo));font-size: 19px;color: #fff;box-sizing: border-box;line-height: normal;border-radius: 100%;height: 35px;width: 35px;display: flex;justify-content: center;align-items: center;padding: 0px !important;}
.toggle-btn img {width: 16px;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(322deg) brightness(102%) contrast(102%);}
.dropdownwrps {float: left;width: auto;position: absolute;right: 90px;display: flex;justify-content: center;align-items: center;cursor: pointer;}
.dropdownwrps .avatar {float: left;width: 32px;}
.dropdownwrps .avatar img {width: 100%;}
.header_common h4 {float: left;width: auto;margin: 0px 0px 0px 10px;font-size: 15px;text-transform: capitalize;}
.panelwrapper.active {flex-basis: calc(100% - 4%);}
.logoutbtn {border: 0px;background: transparent;width: 40px;font-size: 30px;height: 100%;border-radius: 18px 0px 0px 18px;}
.logoutbtn {position: absolute;right: 0;height: 100%;top: 0;width: auto;background: linear-gradient(45deg, var(--maincolor), var(--maincolortwo));color: #fff;padding: 10px 20px;display: flex;justify-content: center;align-items: center;font-size: 28px;text-align: center;}
.logoutbtn i {line-height: normal;}
/* contend header */

/* page content css */
.panelContentwrapper {float: left;width: 100%;padding: 10px;height: calc(100vh - 60px);}
/* page content css */

/* component page  */
.vendor_sections {float: left;width: 100%;position: relative;background-color: #fff;padding: 13px;position: relative;height: calc(100vh - 80px);}
/* component page  */

/* table filter */
.card-header-New {float: left;margin: 0 0 15px;width: 100%;}
.searchBoxwithbtn {float: left;position: relative;width: 260px;}
.searchBoxwithbtn .form-control {border: 1px solid #ddd;border-radius: 0;box-shadow: none!important;font-size: 13px;height: 36px;padding: 7px 35px 7px 10px;}
.searchBoxwithbtn .icon-tabler-search {position: absolute;right: 9px;top: 6px;width: 19px;}
.card-header-right {float: right;width: auto;}
.activestatusselet, .userLimitselect {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 90px;}
.card-header-right select:first-child {margin-right: 10px;}
.card-header-right select {box-shadow: none!important;}
.head_cmn_btn, .head_cmn_btn:focus, .head_cmn_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: var(--maincolortwo) !important;color: #fff !important;}
.head_cmn_btn:hover {background-color: var(--maincolor) !important;}
.head_cmn_btn img {width: 18px;margin: 0 6px 0 0px;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(322deg) brightness(102%) contrast(102%);}
.searchBoxwithbtn .searchclear {cursor: pointer;height: 13px;position: absolute;right: 9px;top: 11px;width: 13px;}
/* table filter */

/* form field */
.comnToggle {float: left;width: auto;}
.comnToggle i {font-size: 25px;cursor: pointer;line-height: normal;}
.comnToggle .bi-toggle2-on {color: var(--maincolortwo);}
.form-sections {float: none;width: 100%;max-width: 650px;margin: 0 auto;display: table;}
.cmnformbtn {font-size: 15px;border-radius: 0px;margin: 0 0 0 10px;padding: 8px 60px;width: auto;float: right;background-color: var(--maincolortwo) !important;color: #fff !important;}
.cmnformbtn:hover {background-color: var(--maincolor) !important;}
.headerform {float: left;width: 100%;border-bottom: 1px solid #eee;padding-bottom: 5px;margin-bottom: 15px;text-align: right;}
.headerform .add_backbtn {font-size: 14px;}
/* form field */

/* table css */
.tableContent {float: left;width: 100%;position: relative;}
.panelContentwrapper .table {border: 1px solid #eee;border-radius: 6px;}
.panelContentwrapper .table td .form-group {margin-bottom: 0px;}
.panelContentwrapper .table thead, .panelContentwrapper .table tr, tbody, td, tfoot, th, thead, tr {float: none;width: auto;}
.table-responsive{overflow-x:auto;-webkit-overflow-scrolling:touch}
.panelContentwrapper .table thead th {background-color: #eee;font-size: 13px;padding: 12px 12px;font-weight: 500;}
.panelContentwrapper .table tbody td {font-size: 13px;padding: 9px 12px;vertical-align: middle;background-color: transparent;}
.panelContentwrapper .table tbody tr:hover, .panelContentwrapper .table tr.active {background-color: #F3F3F9;}
.tablecollapsewraps {background-color: transparent !important;}
.tablecollapsewraps table th {background-color: #f8f8f8;font-size: 12px;padding: 10px 12px;font-weight: 500;}
.tablecollapsewraps table td {font-size: 12px;}
.actionbtn {float: left;width: 17px;height: 17px;padding: 0px;border: 0px;outline: none !important;box-shadow: none;background: transparent;-webkit-transition: 0.2s;-moz-transition: 0.2s;-ms-transition: 0.2s;-o-transition: 0.2s;transition: 0.2s;}
.actionbtn img {float: left;height: 100%;width: 100%;}
.actionbtn.active img {transform: rotate(180deg);-webkit-transition: 0.2s;-moz-transition: 0.2s;-ms-transition: 0.2s;-o-transition: 0.2s;transition: 0.2s;}
.panelContentwrapper .table tbody .actionbtn {margin-right: 12px;}
.panelContentwrapper .table tbody .actionbtn:last-child {margin-right: 0px;}
.panelContentwrapper .table tbody .actiontblebtn:last-child {margin-right: 0px;}
.actiontblebtn {padding: 0px;font-size: 19px;background: transparent;outline: none !important;border: 0px !important;line-height: normal;float: left;width: auto;margin-right: 11px;}
.actiontblebtn img {width: 16px;}
/* table css */

/* common modal */
.AllowPermissionModal .modal-dialog {max-width: 400px;}
.AllowPermissionModal .modal-dialog .modal-header {background: linear-gradient(45deg, var(--maincolor), var(--maincolortwo));border-radius: 0px;color: #fff;}
.AllowPermissionModal .modal-dialog .modal-header .modal-title {font-size: 19px;line-height: normal;}
.AllowPermissionModal .modal-dialog .modal-header .btn-close {filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(322deg) brightness(102%) contrast(102%);opacity: 1;}
.AllowPermissionModal .modal-dialog .modal-content {border-radius: 0px;}
.AllowPermissionModal .modal-dialog .modal-body {text-align: center;}
.AllowPermissionModal .modal-dialog .modal-body p {font-size: 15px;margin: 0;float: left;width: 100%;}
.AllowPermissionModal .modal-dialog .modal-body p h4 {float: left;width: 100%;margin: 5px 0 0;font-size: 20px;}
.AllowPermissionModal .modal-dialog .modal-content .modal-footer .btn {border-radius: 0px;font-size: 14px;}
.spinner-border {width: 18px;height: 18px;border-width: 3px;}
/* common modal */

/* country code start here */
.selectNoInputField {float: left;width: 100%;border: 1px solid #E0E0E5;position: relative;display: flex;}
.selectNoInputField input {height: 40px;border: 0px;padding: 10px 10px;font-size: 13px;outline: none;width: 100%;}
.selectNoInputField .inputBoxSelect {width: 100px;text-align: left;margin: 0px;position: relative;padding-left: 10px;}
.selectNoInputField .inputBoxSelect::after{content: "";float: left;width: 1px;height: 20px;background-color: #c1c1c1;position: absolute;right: 0;top: 11px;}
.form-group.error .selectNoInputField {border-color: #F84646;}
.form-group.error .form-groupfiled.expirydatswrapper .SiXSY, .form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {border-color: #F84646 !important;}
.countselet {font-size: 12px;float: left;width: auto;margin: 0 1px 0 0;color: #333;}
.flagsDropdown {float: left;width: 220px;margin: 0px;padding: 0px;position: absolute !important;z-index: 9;background-color: #fff;box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);left: 0;bottom: 51px;top: auto;border: 0px;}
.flagsDropdownMain a span {float: left;width: 80%;text-overflow: ellipsis;overflow: hidden;}
.flagsDropdownMain {float: left;width: 100%;padding: 0px;margin: 0px;max-height: 150px;overflow: auto;}
.flagsDropdownMain a {float: left;width: 100%;list-style: none;display: flex;padding: 10px 13px;font-size: 12px;cursor: pointer;text-align: left;vertical-align: middle;align-items: center;}
.flagsDropdownMain a:hover {background-color: #eee;}
.flagsDropdownMain a img {width: 26px;margin: 0px 8px 0px 0px;}
.selectNoInputField .inputBoxSelect .selectEmojis {padding: 0px;border: 0px;height: 100%;width: 100%;display: flex;position: relative;align-items: center;text-align: center;justify-content: flex-start;background-color: transparent;margin: 0px;}
.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {width: 20px;height: 20px;border-radius: 100%;margin: 0px 7px 0px 0px;object-fit: cover;}
.noteemail {color: #9c9c9c;font-size: 15px;}
.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {width: 7px;margin: 0px 0px 0px 3px;}
.btn.formcomnbtn {background-color: #1D1D1D;border-color: #1D1D1D;width: 100%;padding: 11px 45px;float: right;font-size: 16px;color: #cecece;border-radius: 0;}
.selectNoInputField.error-form {border-color: red;}
/* country code end here */

/* view vendor tabs start view */
.vendor_view_sections {float: left;width: 100%;position: relative;height: 100%;}
.tabs_content {float: left;width: 100%;margin-bottom: 22px;display: flex;justify-content: space-between;align-items: center;position: relative;column-gap: 10px;padding: 0px;}
.tabs_content li {list-style: none;float: left;width: 100%;border-radius: 30px;padding: 9px 10px;background-color: #fafafa;text-align: center;cursor: pointer;font-size: 14px;position: relative;}
.tabs_content li:hover, .tabs_content li.active {background-color: #008fff;color: #fff;font-weight: 500;}
.tabs_content li::after {content: "\F229";font-family: 'bootstrap-icons';position: absolute;bottom: -23px;top: auto;color: #fafafa;font-size: 25px;left: 0;right: 0;}
.tabs_content li:hover::after, .tabs_content li.active::after {color: #008fff;}
.tabs_content_wrap {float: left;width: 100%;height: calc(100% - 65px);position: relative;}
.dashBordcountwraps {float: left;width: 100%;column-count: 3;column-gap: 10px;margin-bottom: 20px;display: flex;flex-wrap: wrap;}
.dashboardWrapper {float: left;width: 100%;height: calc(100vh - 170px);position: relative;}
.dashBordcountwraps .dashboardcolumn {float: left;flex-basis: 32.6%;border: 1px solid #ddd;margin: 0 0 10px;padding: 15px 15px;border-radius: 15px;line-height: normal;display: flex;align-items: center;display: flex;align-items: center;cursor: pointer;}
.dashBordcountwraps .dashboardcolumn .iconfor {float: left;width: 53px;height: 53px;border-radius: 100%;margin: 0px 18px 0 0;}
.dashboard_wrapper .dashBordcountwraps .dashboardcolumn .iconfor {border-radius: 12px !important;}
.dashBordcountwraps .dashboardcolumn .iconfor img {float: left;width: 100%;filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(322deg) brightness(102%) contrast(102%);height: 100%;}
.dashBordcountwraps .dashboardcolumn .dashboardcolumn h5 {margin: 0px;font-size: 18px;}
.dashcoldetails {float: left;width: auto;position: relative;}
.dashcoldetails h5 {float: left;width: 100%;margin: 0 0 0;font-size: 15px;}
.dashcoldetails h1 {float: left;width: 100%;font-size: 30px;margin: 0px;font-weight: 700;}
.graph_wrapper {float: left;width: 100%;position: relative;}
.graphColumn {float: left;width: 100%;border: 1px solid #ddd;margin: 0 0 15px;padding: 15px 15px;border-radius: 15px;line-height: normal;}
.graphColumn h4 {float: left;width: 100%;border-bottom: 1px solid #ddd;font-size: 18px;padding: 0 0 10px;}
.graphcomnset {float: left;width: 100% !important;}
/* view vendor tabs and view */

/* loader css start here */
.loaderBckground {float: left;width: 100%;position: absolute;left: 0;top: 0;height: 100%;display: flex;justify-content: center;align-items: center;backdrop-filter: blur(4px);background: rgba(255,255,255,0.7);flex-direction: column;}
.loaderBckground .spinner-border {width: 70px;height: 70px;}
.loaderBckground h4 {font-size: 15px;margin: 20px 0 0 0;}
/* loader css end here */

/* pagination css start here */
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 9px 0px 0px;}
.paginationCustom li:first-child, .paginationCustom li:last-child {display: none;}
.paginationCustom li .rpb-item--active {background-color: var(--maincolor);}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;border-radius: 100%;}
/* pagination css end here */

/* badge css */
.badge.bg-danger, .badge.bg-success {font-size: 10px;font-weight: 500;letter-spacing: .5px;}
.badge.bg-success {background-color: rgba(47,179,68,.2)!important;color: #0ca678;}
.badge.bg-danger {background-color: rgb(214,57,57, 0.2) !important;color: #c13333;letter-spacing: 0.5px;font-weight: 500;font-size: 10px;}
/* badge css */

/* logout modal */
h4.logout_text {display: flex;justify-content: center;flex-direction: column;font-size: 17px !important;}
h4.logout_text i {font-size: 35px;color: red;margin: 0 0 4px;}
/* logout modal */

/* session list css */
.deviceswrapper {float: left;width: 100%;height: 100%;position: relative;}
.deviceswrapper li {float: left;width: 100%;position: relative;list-style: none;padding: 8px 0px;display: flex;}
.deviceswrapper li:hover {background-color: #fafafa;}
.deviceswrapper li .BrowserImage {float: left;width: auto;margin: 0 14px 0 0px;position: relative;}
.deviceswrapper li .BrowserImage img {width: 43px;margin: 2px 0 0;}
.deviceswrapper li .Tooltip-Wrapper {position: absolute;right: 5px;top: 17px;}
.deviceswrapper ul {float: left;width: 100%;padding: 0px;margin: 0px;}
.Deactivatebtn img {width: 25px;}
.Deactivatebtn {border: 0px;background: transparent;position: absolute;right: 10px;top: 15px;font-size: 23px;line-height: normal;}
.deviceswrapper li .browserDetails {float: left;width: auto;}
.deviceswrapper li .browserDetails h4 {font-size: 14px;letter-spacing: 0.3px;margin: 2px 0 1px;font-weight: 600;}
.deviceswrapper li .browserDetails span {font-size: 12px;}
.deviceswrapper li::after {content: "";float: left;width: calc(100% - 62px);height: 1px;background-color: #f3f3f3;position: absolute;right: 0;bottom: 0;}
/* session list css */

.discountwrps {width: auto;float: none;display: inline-block;background-color: #00e800;padding: 5px 8px;box-sizing: border-box;color: #fff;border-radius: 9px;font-size: 12px;}
.cmnformbtnlink {font-size: 15px;border-radius: 0px;margin: 0 0 0 10px;padding: 8px 15px;width: auto;float: right;}
.form-sections-reset-password {padding: 19px 25px;border: 1px solid #ddd;border-radius: 10px;margin: 20px auto 0;}
.form-sections-reset-password h4 {float: left;width: 100%;font-size: 23px;margin: 0 0 15px;}

/* mobile number arrow hide css */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}